
import { Component, OnInit } from '@angular/core';

import { cSession } from '@auth/constant/auth-constant';
import { IreApiService } from '@ire/services/ire-api.service';

import { StorageService } from '@ire/services/storage.service';

import { ModuleInterface } from '@semantics/interfaces/Module';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {

	public listModules: ModuleInterface[];
	public user: any;
	public loading: boolean;
	constructor(
		private sStorage: StorageService,
		private sApi: IreApiService,
	) { }

	ngOnInit(): void {
		this.listModules = this.sStorage.get(cSession.MODULES);
		this.user = this.sStorage.get(cSession.USER);
	}
	async update() {
		this.loading = true;
		let res = await this.sApi.get('usuario/rol/' + this.user.id);
		this.sStorage.set(cSession.MODULES, res.modulos);
		this.listModules = res.modulos;
		this.loading = false;

	}

}
