import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noimage'
})
export class NoimagePipe implements PipeTransform {
  transform(images: any): any {
    let imgDefault = "./assets/img/noimage.png";
    console.log('images :>> ', images);
    if (!images || images == '') {
      return imgDefault;
    }
    if (images) {
      return images;
    }
    return imgDefault;
  }

}
