import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-validation',
  templateUrl: './input-validation.component.html',
  styleUrls: ['./input-validation.component.css']
})
export class InputValidationComponent implements OnInit {

  @Input() control: FormControl;
  @Input() modelo: string;
  @Input('label') valor: string;
  errores = []

  constructor() {

  }

  ngOnInit() {

    this.valor = this.formatearCadena(this.valor);
    this.modelo = this.formatearCadena(this.modelo);

    this.errores = [
      { error: "required", mensaje: `El campo ${this.valor ? this.valor : this.modelo} es requerido.` },
      { error: "minlength", mensaje: `El campo ${this.valor ? this.valor : this.modelo} debe tener mas de ${this.control.errors ? this.control.errors.minlength ? this.control.errors.minlength.requiredLength : '' : ''} caracteres.` },
      { error: "maxlength", mensaje: `El campo ${this.valor ? this.valor : this.modelo} debe tener menos de ${this.control.errors ? this.control.errors.maxlength ? this.control.errors.maxlength.requiredLength : '' : ''} caracteres.` },
      { error: "min", mensaje: `El campo ${this.valor ? this.valor : this.modelo} debe ser mayor que ${this.control.errors ? this.control.errors.min ? this.control.errors.min.min : '' : ''}.` },
      { error: "max", mensaje: `El campo ${this.valor ? this.valor : this.modelo} debe ser menor que ${this.control.errors ? this.control.errors.max ? this.control.errors.max.max : '' : ''}.` },
      { error: "pattern", mensaje: `Ajustese al formato solicitado.` },
      { error: "email", mensaje: `Ingrese un email válido. Ejemplo: minombre@gmail.com` },
      { error: "text", mensaje: `Solo se admiten letras, números, espacios, puntos y guiones.` },
      { error: "dni", mensaje: `Ingrese un número de dni válido` },
      { error: "ruc", mensaje: `Ingrese un número de ruc válido` },
      { error: "server", mensaje: this.control.errors ? this.control.errors.server : "" },
    ];

  }

  formatearCadena(modelo: string): string {

    if(modelo)
      if (modelo.includes('_id')) {

        const splittedString = modelo.split('_');
        return splittedString[0];

      }

    return modelo;
  }

}
