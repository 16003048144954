import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from '@ire/components/page404/page404.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';


const routes: Routes = [
    {
        path: 'profile', component: UserProfileComponent,
    },
    { path: '', pathMatch: 'full', redirectTo: 'profile' },

    { path: '**', component: Page404Component },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserRoutingModule { }
