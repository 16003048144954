<div class="form-group {{class}}" [formGroup]="form" >
  <label for="id_{{label}}" *ngIf="label">
    {{label}}:
    <span *ngIf="requerido" class="text-danger">(*)</span>
  </label>
  <select class="form-control pointer" name="{{modelo}}" formControlName="{{modelo}}" id="id_{{label}}"
    [ngClass]="{'is-invalid':form.get(modelo).invalid  && form.get(modelo).touched}">
    <option [ngValue]="null">-- {{placeholder}} --</option>
    <option [ngValue]="item[value]" class="pointer" *ngFor="let item of list">
      {{item[key]}}
    </option>
  </select>
  <app-input-validation [control]="form.get(modelo)" [modelo]="modelo" [label]="show"></app-input-validation>
</div>
