

import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';


import { HomeComponent } from './views/home/home.component';

export const routes_project: Routes = [

  {
    path: '',

    //canActivate : [CheckAuthGuard],

    //children : ProjectRoutingModule

    children: [

      { path: 'home', component: HomeComponent },

      {
        path: 'user', loadChildren: () => import('./views/user/user.module')
          .then(m => m.UserModule)
      },

      {
        path: 'seguridad', loadChildren: () =>
          import('./views/rol/rol.module')
            .then(m => m.RolModule),
      },
      { path: 'maintenance', loadChildren: () => import('./views/maintenance/maintenance.module').then(m => m.MaintenanceModule) },
      { path: 'administrator', loadChildren: () => import('./views/administrator/administrator.module').then(m => m.AdministratorModule) },
      { path: '', pathMatch: 'full', redirectTo: 'home' },

      { path: '**', pathMatch: 'full', redirectTo: 'home' },
    ]

  },


];

@NgModule({

  imports: [RouterModule.forChild(routes_project)],

  exports: [RouterModule]
})

export class ProjectRoutingModule { }

