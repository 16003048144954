import { Component, OnInit } from '@angular/core';
import { IreAlertService } from '../../services/ire-alert.service';

@Component({
  selector: 'ire-alert',
  templateUrl: './ire-alert.component.html'
})
export class IreAlertComponent
{
    constructor(public ireAlertService: IreAlertService) { }
}