
<nav class="topnav navbar navbar-expand shadow navbar-light bg-white" id="sidenavAccordion">

    <a class="navbar-brand puntero" (click)="goToDahsboard()">
        <div class="d-flex">
            <img  src="assets/img/footer-slogan.png" alt="logo-empresa" >
        </div>
    </a>

    <button class="btn btn-icon bg-primary text-white order-1 order-lg-0 mr-lg-2" (click)="toggleClass();" id="sidebarToggle">
        <i class="fas fa-bars"></i>
    </button>

    <ul class="navbar-nav align-items-center ml-auto">

        <li class="nav-item dropdown no-caret mr-2 dropdown-user">

            <a class="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage" href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                <img class="img-fluid" src="assets/img/person-circle.svg" *ngIf="auth.getUser()?.foto == null" />

                <img class="img-fluid" [src]="storage + auth.getUser()?.foto" *ngIf="auth.getUser()?.foto != null" />

            </a>

            <div class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up" aria-labelledby="navbarDropdownUserImage">

                <h6 class="dropdown-header d-flex align-items-center">

                    <img class="dropdown-user-img" src="assets/img/person-circle.svg" *ngIf="auth.getUser()?.foto == null"/>

                    <img class="dropdown-user-img" [src]="storage + auth.getUser()?.foto" *ngIf="auth.getUser()?.foto != null" />

                    <div class="dropdown-user-details">

                        <div class="dropdown-user-details-name">
                            {{ auth.getUser()?.persona?.nombres }}
                        </div>

                        <div class="dropdown-user-details-email">
                            {{ auth.getUser()?.persona?.correo  }}
                        </div>

                    </div>

                </h6>

                <div class="dropdown-divider"></div>
                <!-- <a class="dropdown-item" style="cursor:pointer" (click)="goToHome()">

                    <div class="dropdown-item-icon">
                    <i data-feather="settings"></i>
                    </div>

                    Ir al Inicio

                    </a> -->
                    <a class="dropdown-item" style="cursor:pointer" (click)="goToAccount()">

                        <div class="dropdown-item-icon">
                            <i data-feather="settings"></i>
                        </div>

                        Cuenta

                    </a>

                    <a class="dropdown-item" style="cursor:pointer" (click)="logout();">

                        <div class="dropdown-item-icon">
                            <i data-feather="log-out"></i>
                        </div>

                        Cerrar Sesión

                    </a>

            </div>

        </li>

    </ul>

</nav>

