import { Component } from '@angular/core';
import { ModalService } from '../../services/modal.service';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent {

  ngOnInit() {
    
  }
  constructor(public modal : ModalService) { }

  cancelAlert () {
    
    let properties = this.modal.properties;

    if (properties.methodClosed)  properties.methodClosed()

    this.modal.resetProperties();

  }

  acceptAlert () {
    
    let properties = this.modal.properties;
    
    if (properties.methodAccept)  properties.methodAccept()

    this.modal.resetProperties();
  }

}
