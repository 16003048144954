
<div 
    class="modal fade" id="full-view-{{ name }}"  
    tabindex="-1" aria-hidden="true" role="dialog" 
    attr.aria-labelledby="full-view-{{ name }}" 
    style="    padding-top: 17px !important;
    padding-right: 17px  !important;
    padding-bottom: 17px !important;
    padding-left: 17px !important;"
>

    <div class="modal-dialog full_modal-dialog  modal-dialog-scrollable" role="document">
         
        <div class="modal-content full_modal-content">
        
            <div class="modal-header" style="border: none;">
                <h5 class="modal-title" id="full-view-{{ name }}">
                    {{ title }}
                </h5>
                <button type="button" 
                    class="close" 
                    data-dismiss="modal" 
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                
                </button>
            
            </div>
            
            <div class="modal-body">
            
                <ng-content></ng-content>    
            
            </div>
            
        </div>

    </div>

</div>