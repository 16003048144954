
<div class="col-md-12">

    <div class="row">

        <div class="col-md-4">
            <!--
            <div class="card lift h-100 bg-light border-right border-lg rounded border-primary p-3 mb-3">

                <div class="dropdown no-caret text-end">
                    
                    <span class="text-center pl-2">
                        Módulo
                    </span>

                    <button class="btn btn-transparent-dark btn-icon dropdown-toggle" id="modulo" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-ellipsis-v"></i>
                    </button>
        
                    <div class="dropdown-menu dropdown-menu-right animated--fade-in-up" aria-labelledby="modulo">
                        
                        <a class="dropdown-item">Editar</a>
                        <a class="dropdown-item">Eliminar</a>

                    </div>

                </div>
                
                Modulo 1

            </div>  
            -->
            
            <app-skeleton [items]="3"  [vertical]="true"></app-skeleton>
            
        </div>

        <div class="col-md-8">

            <div class="">
                {{rol | json }}
            </div>

        </div>

    </div>

</div>