import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.css']
})
export class InputTextComponent implements OnInit {

  Random : Number = Math.floor((Math.random() * 10000) + 1);

  @Input() label: string;
  @Input('req') requerido : boolean = true;
  @Input('form') form: FormGroup;
  @Input('model') modelo: string;
  @Input('show') show: string = "";
  @Input('placeholder') placeholder: string = "";
  @Input('type') type: string = "text";
  @Input() maxLength = 10000;

  constructor() { }

  ngOnInit() {
  }


}
