
import { environment } from 'src/environments/environment';


export const cApi = {

    SERVER: environment.baseApiUrl,
    STORAGE: environment.baseStorageUrl,
    TOKEN: `${window.location.hostname}@ire_basic@token`,
    IMAGE: './server/storage/app/avatars/avatar.png',

}
