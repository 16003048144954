
import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { cSession } from '@auth/constant/auth-constant';

import { AuthService } from '@auth/services/auth.service';
import { cApi } from '@ire/constant/ire-constant';
import { IreApiService } from '@ire/services/ire-api.service';

import { ModalService } from '@ire/services/modal.service';
import { StorageService } from '@ire/services/storage.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {


	user : any;
	storage : string           = cApi.STORAGE;
	logo:any                   = null;
	baseStorage                = environment.baseStorageUrl;
	loadingAlerts              = false;
	notifications:any          = [];
	show_notifications:boolean = false;

	constructor(
		private sModal: ModalService,
		public auth: AuthService,
		private router: Router,
		private sStorage: StorageService,
		private sApi : IreApiService,

	) {


	 }

	ngOnInit(): void {
		//let string_class= $('body').attr('class');
		//if(string_class=='nav-fixed sidenav-toggled')
		//{
			//$("body").toggleClass("sidenav-toggled");

		//}

	}

	logout = () => {

		this.sModal.question('¿Seguro que desea cerrar Sesión?', () => {

			this.auth.clearCredentials();

			this.router.navigate(['/login']);

		},
			() => {
				console.log('Cancel logout');
			});


	};

	toggleClass() {
		$("body").toggleClass("sidenav-toggled");
	};
	goToAccount(){
		let user = this.sStorage.get(cSession.USER);
		if(user.docente)
		{
			this.router.navigate(['/app/docente/perfil']);
		}else if(user.estudiante){
			this.router.navigate(['/app/estudiante/perfil']);
		}else {
			this.router.navigate(['/app/user/profile']);

		}


	}
	goToHome(){
			this.router.navigate(['/login']);
	}
	goToDahsboard(){
			this.router.navigate(['/app/home']);
	}

	startAnimation()
	{
		let string_class:string= $('#icon-bell-a').attr('class');
		console.log('string_class :>> ', string_class);
		if(!string_class.includes("bells-animation"))
		{
			$("#icon-bell-a").toggleClass("bells-animation")
		}
	}
	stopAnimation()
	{
		let string_class:string= $('#icon-bell-a').attr('class');
		console.log('string_class :>> ', string_class);

		if(string_class.includes("bells-animation"))
		{
			$("#icon-bell-a").toggleClass("bells-animation")
		}
	}
	openNotification()
	{
		this.sModal.openModal("modal_noti");
	}
	getDateFormat(date) {
		if (date == 0 || !date) {
		  return "--/--/----";
		}
		var d = new Date(date);
		var hh = d.getHours();
		var m: any = d.getMinutes();
		var s: any = d.getSeconds();
		var dd = "AM";
		var h = hh;
		if (h >= 12) {
		  h = hh - 12;
		  dd = "PM";
		}
		if (h == 0) {
		  h = 12;
		}
		m = m < 10 ? "0" + m : m;

		s = s < 10 ? "0" + s : s;

		var replacement = h + ":" + m;
		replacement += " " + dd;
		return d.toISOString().substring(0, 10) + " " + replacement
	  }
}
