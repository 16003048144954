import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.css']
})
export class TextAreaComponent implements OnInit {

  @Input() label: string;
  @Input('req') requerido: boolean = true
  @Input('form') form: FormGroup;
  @Input('model') modelo: string;
  @Input('show') show: string = '';
  @Input('placeholder') placeholder: string = '';
  @Input() maxLength = 255;
  @Input() rows = 4;

  constructor() { }

  ngOnInit(): void {
  }

}
