
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { IconsModule } from '@ire/icons/icons.module';

import { AlertComponent } from '@ire/components/alert/alert.component';

import { ModalComponent } from '@ire/components/modal/modal.component';

import { FullViewComponent } from './components/full-view/full-view.component';

import { IreApiService } from './services/ire-api.service';

import { SkeletonComponent } from './components/skeleton/skeleton.component';

import { LoopNumber } from './pipes/loop-number.pipe';

import { EmptyResultComponent } from './components/empty-result/empty-result.component';
import { LoadingComponent } from './components/loading/loading.component';
import { FilterPipe } from './pipes/filter.pipe';

import { IreAlertComponent } from './components/ire-alert/ire-alert.component';
// import { EditorComponent } from './components/editor/editor.component';

// import { QuillModule } from 'ngx-quill';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextComponent } from './components/input-text/input-text.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { InputValidationComponent } from './components/input-validation/input-validation.component';
import { InputSelectComponent } from './components/input-select/input-select.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { InputSearchComponent } from './components/input-search/input-search.component';
import {HeaderContentOverlapComponent} from './components/header-content-overlap/header-content-overlap.component';
import {ServerImagePipe} from './pipes/server-image.pipe';

@NgModule({

  exports: [
    AlertComponent,
    ModalComponent,
    FullViewComponent,
    SkeletonComponent,
    LoopNumber,
    EmptyResultComponent,
    LoadingComponent,
    FilterPipe,
    ServerImagePipe,

    IreAlertComponent,
    // EditorComponent,
    InputTextComponent,

    InputNumberComponent,

    InputValidationComponent,

    InputSelectComponent,

    TextAreaComponent,
    HeaderContentOverlapComponent,
    InputSearchComponent

  ],

  imports: [
    CommonModule,
    IconsModule,
    // QuillModule.forRoot({
    //   suppressGlobalRegisterWarning: true
    // }),
    FormsModule,
    ReactiveFormsModule
  ],

  declarations: [
    AlertComponent,
    ModalComponent,
    HeaderContentOverlapComponent,
    FullViewComponent,
    SkeletonComponent,
    LoopNumber,
    EmptyResultComponent,
    LoadingComponent,
    FilterPipe,

    IreAlertComponent,

    // EditorComponent,

    InputTextComponent,

    InputNumberComponent,

    InputValidationComponent,

    InputSelectComponent,

    ServerImagePipe,
    TextAreaComponent,
    InputSearchComponent
  ],
  providers: [
    // ModalService,
    IreApiService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})

export class IreModule { }
