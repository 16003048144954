import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.css']
})
export class InputSelectComponent implements OnInit {

  @Input() label: string;
  @Input('req') requerido : boolean = true;
  @Input('form') form: FormGroup;
  @Input('model') modelo: string;
  @Input('show') show = '';
  @Input() placeholder = 'Seleccione un elemento';
  @Input() list: any = [];
  @Input() value: string = 'id';
  @Input() key: string = 'nombre';
  @Input() class: string = '';

  constructor() { }

  ngOnInit(): void {

  }

}
