<header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">

  <div [ngClass]="isHeaderFluid ? 'container-fluid' : 'container'">

    <div class="page-header-content pt-3 pb-3">

      <div class="row align-items-center justify-content-between">

        <div class="col-auto mt-4">

          <h1 class="page-header-title">

            <div class="page-header-icon" *ngIf="icon != null">

              <i class="fa fa-{{ icon }}" ></i>

            </div>

            {{ title }}

          </h1>

          <div class="page-header-subtitle">

            {{ subTitle }}

          </div>

        </div>

        <div class="col-12 col-xl-auto mt-4">

          <ng-content></ng-content>

        </div>

      </div>

    </div>

  </div>

</header>
