import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonServices {
  loaderBtn = (id, message) => {
    let button: any = document.getElementById(id);
    var loadingText = "<i class='fa fa-spinner fa-spin mr-1'></i> " + message;
    if (button.innerHTML !== loadingText) {
      button.dataset.originalText = button.innerHTML;
      button.innerHTML = loadingText;
      button.disabled = true;
    }
  };

  stopLoaderBtn = (id) => {
    let button: any = document.getElementById(id);
    button.innerHTML = button.dataset.originalText;
    button.disabled = false;
  };
  dataURLtoFile(dataurl, filename) {
    //data:text/plain;base64,aGVsbG8gd29ybGQ=
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  isUrl(s) {
    var regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
  }
}
