import { Injectable } from '@angular/core';

import { CanActivate, Router } from '@angular/router';
import { cSession } from '@auth/constant/auth-constant';
import { StorageService } from '@ire/services/storage.service';

import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CheckAuthGuard implements CanActivate {
  
  constructor (
    private auth : AuthService,
    private route : Router,
    private sStorage: StorageService,
  ) {}

  canActivate () : boolean {

    if (!this.auth.isLogged()) {
      this.sStorage.set(cSession.USER, null);

			this.sStorage.set(cSession.TOKEN, null);

			this.sStorage.set(cSession.MENU, null);

      this.sStorage.set(cSession.MODULES, null);
      
      this.route.navigate(['/login']);
      
      return false;

    } else {
      
      return true;
    
    }
    
     
  }
  
}
