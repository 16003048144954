<app-navbar></app-navbar>

<div id="layoutSidenav">

    <app-sidebar></app-sidebar>

    <div id="layoutSidenav_content">

        <main>

            <router-outlet></router-outlet>

        </main>

    </div>

</div>