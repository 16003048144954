
import { Component, OnInit } from '@angular/core';

import { FormControl, FormGroup } from '@angular/forms';

import { AuthService } from '@auth/services/auth.service';

import { ModalService } from '@ire/services/modal.service';

import { StorageService } from '@ire/services/storage.service';

import { cSession } from '@auth/constant/auth-constant';

import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

	spin : Boolean;

	loginForm = new FormGroup({
		nombre	: new FormControl(''),
		clave 	: new FormControl(''),
	});

	constructor(
		private auth 	 : AuthService,
		private sModal 	 : ModalService,
		private sStorage : StorageService,
		private router	 : Router
	) { }

	ngOnInit(): void {}

	async login () : Promise<any> {

		this.spin = true;

		let res = await this.auth.login(this.loginForm.value);

		if (res.success) {

			this.sStorage.set(cSession.USER,res.user);

			this.sStorage.set(cSession.ROLES,res.roles);

			this.sStorage.set(cSession.TOKEN,res.ire_token);

			this.sStorage.set(cSession.MENU,res.menu_data?.menu);

			this.sStorage.set(cSession.MODULES,res.menu_data?.modulos);

			this.spin = false;

			this.router.navigate(['/dashboard']);

		} else {

			this.spin = false;

			this.sModal.error(res.message);

		}


	}

}
