
import { NgModule } from '@angular/core';

import { ProjectRoutingModule } from '@project/project-routing.module';

import { IconsModule } from '@ire/icons/icons.module';

import { IreModule } from '@ire/ire.module';

/** components */

/** views */

import { HomeComponent } from './views/home/home.component';
import { UserModule } from './views/user/user.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDropzoneModule } from 'ngx-dropzone';
import {CommonModule} from '@angular/common';


@NgModule({

  exports: [
    HomeComponent
  ],

  imports: [
    ProjectRoutingModule,
    CommonModule,
    IconsModule,
    IreModule,
    UserModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxDropzoneModule,
  ],

  declarations: [
    HomeComponent,
  ]

})
export class ProjectModule { }
