
import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';

import { UserInterface } from '@auth/interfaces/User';

import { cSession, cLogin } from '@auth/constant/auth-constant';

import { StorageService } from '@ire/services/storage.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})

export class AuthService {

	constructor(
		
		private sStorage : StorageService,	
		private http 	 : HttpClient,
		private router: Router,

	) { }

	async login ( user : UserInterface ) : Promise<any> {
		
		try {

			const res = await this.http.post(cLogin.AUTH, user).toPromise();
			
			return Promise.resolve(res);

		} catch (error) {

			return error.error;
		}
		
	}

	
	async logout () : Promise<any> {
		
		try {

			const res = await this.http.post(cLogin.LOGOUT,{}).toPromise();
			
			return Promise.resolve(res);

		} catch (error) {

			return error.error;
		}
		
	}

	public clearCredentials () {
		this.sStorage.delete(cSession.USER);
		this.sStorage.delete(cSession.TOKEN);
		this.sStorage.delete(cSession.MENU);
		this.sStorage.delete(cSession.MODULES);
		this.sStorage.delete(cSession.ROLES);
	};  

	public logoutFor401(){
		this.clearCredentials();
		this.router.navigate(['/login']);
	}

	public getUser(): string {
		return this.sStorage.get(cSession.USER);
	}

	public getMenu(): string {
		return this.sStorage.get(cSession.MENU);
	}

	public getRoles(): string {
		return this.sStorage.get(cSession.ROLES);
	}
	public getToken(): string {
		return this.sStorage.get(cSession.TOKEN);
	}

	public isLogged () : boolean {
		
		return this.getToken() != null ? true : false;
	}

}
