import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header-content-overlap',
  templateUrl: './header-content-overlap.component.html',
  styleUrls: ['./header-content-overlap.component.css']
})
export class HeaderContentOverlapComponent implements OnInit {

  @Input() title: string;
  @Input() subTitle: string;
  @Input() icon: string;
  @Input() isHeaderFluid = false;

  constructor() { }

  ngOnInit(): void {
  }

}
