
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { IconsModule } from '@ire/icons/icons.module';

import { RouterModule } from '@angular/router';

import { IreModule } from '@ire/ire.module';

import { SemanticsComponent } from '@semantics/components/semantics/semantics.component';

import { NavbarComponent } from '@semantics/components/navbar/navbar.component';

import { SidebarComponent } from '@semantics/components/sidebar/sidebar.component';

import { RolComponent } from './components/rol/rol.component';


import { MenuComponent } from './components/menu/menu.component';

import { ReactiveFormsModule } from '@angular/forms';


@NgModule({

    imports: [
        CommonModule,
        IconsModule,
        RouterModule,
        IreModule,
        ReactiveFormsModule
    ],

    exports: [
        SemanticsComponent,
        NavbarComponent,
        SidebarComponent,
    ],

    declarations: [
        SemanticsComponent,
        NavbarComponent,
        SidebarComponent,
        RolComponent,
        MenuComponent
    ],

    providers: [],
})

export class SemanticsModule { }
