<header
  class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10"
>
  <div class="container">
    <div class="page-header-content pt-4">
      <div class="row align-items-center justify-content-between">
        <div class="col-auto mt-4">
          <h1 class="page-header-title">
            <div class="page-header-icon">
              <i-feather name="activity"></i-feather>
            </div>
            PANEL DE ADMINISTRACIÓN
          </h1>

        </div>

      </div>
    </div>
  </div>
</header>

<div class="container mt-n10">
  <app-skeleton [items]="6" *ngIf="loader"></app-skeleton>
  <div class="card" *ngIf="!loader">
    <div class="card-header text-right">
      <button class="btn btn-primary" (click)="generateJSON()" [disabled]="spin_loader">Construir y mostrar en página</button>
    </div>
    <div class="card-body">
      <h2 class="mb-3">Paginas</h2>

      <div class="row">
        <div class="col-md-4 mb-4" *ngFor="let page of pages">
          <div class="card lift h-100 pointer" (click)="goTo(page,'page')">
            <div class="card-body d-flex justify-content-center flex-column">
              <div class="d-flex align-items-center justify-content-center">
                <div class="mr-3 text-center">
                  <h5>{{ page.name }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <!--<h2 class="mb-3">Secciones</h2>

      <div class="row">
        <div class="col-md-4 mb-4" *ngFor="let section of sections">
          <div class="card lift h-100 pointer" (click)="goTo(section,'section')">
            <div class="card-body d-flex justify-content-center flex-column">
              <div class="d-flex align-items-center justify-content-center">
                <div class="mr-3 text-center">
                  <h5>{{ section.name }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>-->


      <!--
       <hr />
      <div class="row">
        <div class="col-md-4 mb-4">
          <div class="card lift h-100">
            <div class="card-body d-flex justify-content-center flex-column">
              <div class=""d-flex align-items-center justify-content-center>
                <div class="mr-3 text-center">
                  <h5>Opciones Generales</h5>
                  <button class="btn btn-primary btn-block" (click)="goToGeneral()">
                    Ver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      -->

    </div>
  </div>
</div>

<app-modal [name]="modal.name" [title]="modal.title" [size]="'lg'">
  hola app-modal

  <div class="row" style="justify-content: space-evenly">
    <button class="btn btn-default" data-dismiss="modal">Cancelar</button>
    <button class="btn btn-primary">Enviar</button>
  </div>
</app-modal>
