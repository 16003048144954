
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

	@Input() name   : string;
	@Input() title  : string;
	@Input() size   : string;
	@Input() scroll : boolean = false;
	@Input() hideHeader : boolean = false;
	@Input() classbody	: string;

	constructor() { }

	ngOnInit() {}

}
