
import { BrowserModule } from '@angular/platform-browser';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { ReactiveFormsModule } from '@angular/forms';

import { AuthModule } from '@auth/auth.module';

import { SemanticsModule } from '@semantics/semantics.module';

import { IreModule } from '@ire/ire.module';

import { ProjectModule } from '@project/project.module';


import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TokenInterceptor } from '@auth/guards/token.interceptor';
import {NgxIziToastModule} from 'ngx-izitoast';

@NgModule({

  declarations: [
    AppComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AuthModule,
    SemanticsModule,
    IreModule,
    NgxIziToastModule,
    ProjectModule,
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],

  bootstrap: [AppComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
