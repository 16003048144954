
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class StorageService {

  constructor() { }

  public set = (name: string, data: any) => {
    localStorage.setItem(btoa(name), btoa(JSON.stringify(data)));
  }

  public get = (name: string) => {
    let data = localStorage.getItem(btoa(name));
    return (data === null) ? undefined : JSON.parse(atob(data))
  }

  public delete = (name: string) => {
    localStorage.removeItem(btoa(name));
  }

  public clear = () => {
    localStorage.clear();
  }

}
