import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class IreAlertService
{
    type: string;
    private enviarTypeSubject = new Subject<string>();
    enviarTypeObservable = this.enviarTypeSubject.asObservable();

    enviarType(type: string){
        this.type = type;
        this.enviarTypeSubject.next(type);
    }
}