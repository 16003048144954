import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
	name: 'serverImage',
	pure: false,
})
export class ServerImagePipe implements PipeTransform {
	transform(url: string | null, quotations = false): string | null {
		// console.log(url);
		if (url) {
			if (!(url.startsWith('http') || url.startsWith('assets'))) {
				url = environment.baseStorageUrl + url;
			}

			if (quotations) {
				url = `"${url}"`;
			}
		}
		// console.log('to ---> ' + url);
		return url;
	}
}
