<div class="form-group" [formGroup]="form">
  <label for="id_{{label}}" *ngIf="label">
    {{label}}:
    <span *ngIf="requerido" class="text-danger">*</span>
  </label>
  <textarea class="form-control ire-text-area" name="{{modelo}}" formControlName="{{modelo}}" id="id_{{label}}"
    placeholder="{{placeholder}}" maxlength="{{maxLength}}" rows="{{rows}}"
    [ngClass]="{'is-invalid':form.get(modelo).invalid  && form.get(modelo).touched}"></textarea>
  <app-input-validation [control]="form.get(modelo)" [modelo]="modelo" [label]="show"></app-input-validation>
</div>
