    
<header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">

    <div class="container">
    
        <div class="page-header-content pt-4">
    
            <div class="row align-items-center justify-content-between">
    
                <div class="col-auto mt-4">
    
                    <h1 class="page-header-title">
                        
                        <div class="page-header-icon"><i data-feather="mouse-pointer"></i></div>
                        
                        Roles de Usuario

                    </h1>

                    <div class="page-header-subtitle">Restyled Bootstrap default buttons and custom developed button components</div>
                
                </div>
                
                <div class="col-12 col-xl-auto mt-4">

                    <button class="btn btn-white btn-sm line-height-normal p-3" (click)="openModalRol(null);">
                        
                        <i class="mr-2 text-primary fas fa-plus"></i>

                        Añadir
                        
                    </button>
                </div>

            </div>
        
        </div>
    
    </div>

</header>

<div class="container mt-n10">
    
    <app-skeleton [items]="6" *ngIf="loader"></app-skeleton>

    <div class="row"  *ngIf="!loader">

        <div  class="col-xl-4 col-md-6 mb-4" *ngFor="let item of listRoles; let identificador = index ">

            <div class="card lift h-100 card-header-actions  border-top-0 border-left-0 border-right-0 border-bottom-lg border-primary h-100">
            
                <div class="card-header">
                    
                    {{ item.nombre }}
            
                    <div class="dropdown no-caret">
            
                        <button class="btn btn-transparent-dark btn-icon dropdown-toggle" id="{{identificador}}" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-ellipsis-v"></i>
                        </button>
            
                        <div class="dropdown-menu dropdown-menu-right animated--fade-in-up" aria-labelledby="{{identificador}}">

                            <a class="dropdown-item" (click)="openModalRol(item);">Editar</a>
                            
                            <a class="dropdown-item" (click)="openModalRol(item);">Eliminar</a>
                        
                        </div>

                    </div>

                </div>

                <div class="card-body text-center">
                    
                    <button class="btn btn-primary btn-sm py-2 px-3" (click)="administrarMenu(item);">
                        
                        Adminsitrar Menú

                    </button>

                </div>

            </div>   
        
        </div>
        
    </div>

</div>

<app-modal [name]="'rolModal'" [title]="titleModal">

    <form [formGroup]="rolForm" (ngSubmit)="sendData();">
                       
        <div class="form-row">
            
            <div class="form-group col-md-6">
                
                <label class="small mb-1" for="inputEmailuser">Nombre</label>

                <input class="form-control" id="inputEmailuser" type="text"   formControlName="nombre" autocomplete="off"/>

            </div>
            
            <div class="form-group col-md-6">

                <label class="small mb-1" for="inputPhoneNumber">Nivel</label>
                
                <input class="form-control" id="inputPhoneNumber" type="text"  formControlName="nivel" autocomplete="off"/>
            
            </div>

            <div class="form-group col-md-12">

                <label class="small mb-1" for="inputPhoneNumber">Alias</label>
                
                <input class="form-control" id="inputPhoneNumber" type="text"  formControlName="alias" autocomplete="off"/>
            
            </div>

        </div>

        <div class="row" style="justify-content: space-evenly;">
            
            <button class="btn btn-primary btn-sm"
                type="submit"
                [disabled] = "rolForm.invalid || spinForm"
            >
                {{ spinForm ? 'Enviando ...  '  : 'Guardar' }}

            </button>

        </div>

    </form>

</app-modal>


<app-full-view [name] = "'menu'">

    <app-menu [rol] = "rolSelected"></app-menu>

</app-full-view>
