import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const ValidatorRUC: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let ruc = control;
    let pattern = /^[1-2][0](\d+){9}$/;
    let hasError: boolean = false;
    if (ruc.value == null) {
    } else
        if (!pattern.test(ruc.value)) {
            hasError = true;
        }
    return hasError ? { 'ruc': true } : null;
};
export const ValidatorDNI: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let dni = control;
    let pattern = /^(\d+){8}$/;
    let hasError: boolean = false;
    if (dni.value == null) {
        hasError = false;
    } else
        if (!pattern.test(dni.value)) {
            hasError = true;
        }
    return hasError ? { 'dni': true } : null;
};