import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { cSession } from 'src/app/auth/constant/auth-constant';
import { IreApiService } from 'src/app/ire/services/ire-api.service';
import { ModalService } from 'src/app/ire/services/modal.service';
import { StorageService } from 'src/app/ire/services/storage.service';
import { ValidatorDNI } from 'src/app/ire/validators/app.validators';
import { CommonServices } from 'src/app/project/services/commons.service';
import { cUser } from '../../constant/user-constant';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  userForm: FormGroup;
  profileForm: FormGroup;
  user: any;
  patternValidNumber = /^(\d+)$/;
  files: File[] = [];
  isSaveFile: boolean = false;
  url: string = '';
  type_user="admin";
  constructor(
    private sApi: IreApiService,
    private sStorage: StorageService,
    private sModal: ModalService,
    private sCommons: CommonServices
  ) { }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      'password': new FormControl('', [Validators.minLength(6)]),
      'passwordRepeat': new FormControl('', [Validators.minLength(6)]),
      'user': new FormControl('', [Validators.required]),
      'id': new FormControl('')

    });
    this.profileForm = new FormGroup({
      'email': new FormControl('', [Validators.email]),
      'phone': new FormControl('', [Validators.pattern(this.patternValidNumber)]),
      'dni': new FormControl('', [Validators.required, ValidatorDNI]),
      'lastname': new FormControl('', [Validators.required]),
      'name': new FormControl('', [Validators.required]),
      'id': new FormControl('')
    });
    this.getUserCurrent();
  }
  getUserCurrent() {
    this.user = this.sStorage.get(cSession.USER);

    if(this.user.docente){
      this.type_user="docente";
    }
    else if(this.user.estudiante) 
    {
      this.type_user="estudiante";
    }
    this.userForm.reset({
      password: "",
      passwordRepeat: "",
      id: this.user.id
    });
    this.profileForm.reset({

      email: this.user.persona?.correo,
      phone: this.user.persona?.celular,
      name: this.user.persona?.nombres,
      lastname: this.user.persona?.apellidos,
      id: this.user.id,
      dni: this.user.persona?.dni
    });
    this.url = ''
    if (this.user.foto != '' && this.user.foto != null)
      this.url = cUser.storageImage + this.user.foto;

  }
  async sendData(key) {
    switch (key) {
      case 0:
        if (!this.valid())
          return
        this.sCommons.loaderBtn('btn-save', 'Guardando cambios');

        this.sApi.post(cUser.updateProfile, this.profileForm.value).then(res => {
          if (res.success) {
            this.sModal.success(res.message);
            this.sStorage.set(cSession.USER, res.info);
            this.getUserCurrent();
          } else {
            this.sModal.error(res.message);

          }
          this.sCommons.stopLoaderBtn('btn-save');

        });
        break;
      case 1:
        if (!this.validPassword())
          return;
        this.sCommons.loaderBtn('btn-password', 'Guardando cambios');
        this.sApi.post(cUser.updateUser, this.userForm.value).then(res => {
          if (res.success) {
            this.sModal.success(res.message);
            this.getUserCurrent();
          } else {
            this.sModal.error(res.message);

          }
          this.sCommons.stopLoaderBtn('btn-password');
          this.sModal.closeModal('change-password');

        });
        break;

      case 2:
        if (this.files.length == 0) {
          return;
        }
        this.sCommons.loaderBtn('btn-update', 'Actualizando foto');
        let formData = new FormData()
        formData.append('foto', this.files[0], this.files[0].name)
        this.sApi.post(cUser.UpdateImage + this.profileForm.get('id').value, formData).then(res => {
          this.user.foto = res.info;
          this.sStorage.set(cSession.USER, this.user);
          this.sModal.success(res.message);
          this.sCommons.stopLoaderBtn('btn-update');
          this.sModal.closeModal('update-profile');
          this.getUserCurrent();

        });
        break;
      default:
        break;
    }
  }
  onSelect(event) {
    this.files = [];
    if (event.addedFiles.length > 0) {
      this.isSaveFile = true;
    }

    this.files.push(event.addedFiles[0]);

  }

  onRemove(event) {
    this.files = [];
    this.isSaveFile = true;

  }
  openModal(key) {
    switch (key) {
      case 0:
        this.files = [];
        this.sModal.openModal('update-profile');
        break;
      case 1:

        this.sModal.openModal('change-password');
        break;
      default:
        break;
    }
  }

  valid() {
    // if (this.profileForm.get('email').errors?.required) {
    //   this.sModal.error('Debe ingresar un email.');
    //   return false;
    // }
    if (this.profileForm.get('name').errors?.required) {
      this.sModal.error('Debe ingresar un Nombre.');
      return false;
    }
    if (this.profileForm.get('lastname').errors?.required) {
      this.sModal.error('Debe ingresar un Apellido.');
      return false;
    }
    else if (this.profileForm.get('email').errors?.email) {
      this.sModal.error('Debe ingresar un email válido.');
      return false;
    }

    return true;
  }
  validPassword() {
    if (this.userForm.get('password').value != '' || this.userForm.get('passwordRepeat').value != '') {
      if (this.userForm.get('password').value !== this.userForm.get('passwordRepeat').value) {
        this.sModal.error('Las contraseñas no coinciden.');
        return false;
      }
      if (this.userForm.get('password').errors || this.userForm.get('passwordRepeat').errors) {
        this.sModal.error('La contraseña debe tener mínimo 6 caracteres.');
        return false;
      }
    }
    return true;
  }
  getCheckPassword() {
    let value = !this.userForm.get('password').pristine && !this.userForm.get('passwordRepeat').pristine && (this.userForm.get('password').value != this.userForm.get('passwordRepeat').value);
    if (value) {
      return 'Las contraseñas no coinciden'
    }
    if (this.userForm.get('password').errors) {
      return 'La contraseña debe tener mínimo 6 caracteres.'
    }
    return '';
  }
  updateFile() {

  }
}
