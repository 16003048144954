
<div class="bg-primary">

    <div id="layoutAuthentication">

        <div id="layoutAuthentication_content">

            <main>

                <div class="container">

                    <div class="row justify-content-center">

                        <div class="col-lg-5">

                            <div class="card shadow-lg border-0 rounded-lg mt-5">

                                <div class="card-header justify-content-center"><h3 class="font-weight-light my-4">Login</h3></div>

                                <div class="card-body">

                                    <form [formGroup]="loginForm" (ngSubmit)="login();">

                                        <div class="form-group">

                                            <label class="small mb-1">Usuario</label>

                                            <input
                                                class="form-control py-4"
                                                type="text"
                                                placeholder="usuario"
                                                formControlName="nombre"
                                                required
                                            />

                                        </div>

                                        <div class="form-group">

                                            <label class="small mb-1">contraseña</label>

                                            <input
                                                class="form-control py-4"
                                                type="password"
                                                placeholder="contraseña"
                                                formControlName="clave"
                                                required
                                            />

                                        </div>

                                        <div class="form-group d-flex align-items-center justify-content-between mt-4 mb-0">


                                            <button
                                                type="submit"
                                                class="btn btn-primary"
                                                [disabled]="!loginForm.valid"
                                                *ngIf="!spin; else loading"
                                            >
                                                Iniciar Sesión
                                            </button>

                                            <ng-template #loading>

                                                <button class="btn btn-primary" type="button" disabled>

                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    {{ ' Enviando ... ' }}
                                                </button>

                                            </ng-template>

                                        </div>

                                    </form>

                                </div>


                            </div>

                        </div>

                    </div>

                </div>

            </main>

        </div>

        <div id="layoutAuthentication_footer">

            <footer class="footer mt-auto footer-dark">

                <div class="container-fluid">

                    <div class="row">

                        <div class="col-md-6 small">Copyright &copy; Grupo Inca 2024 </div>

                    </div>

                </div>

            </footer>

        </div>

    </div>

</div>
