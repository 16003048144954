<header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
    <div class="container-fluid">
        <div class="page-header-content">
            <div class="row align-items-center justify-content-between pt-3">
                <div class="col-auto mb-3">
                    <h1 class="page-header-title">
                        <div class="page-header-icon">
                            <i-feather class="mr-2" name="user"></i-feather>
                        </div>
                        Configuración de la cuenta
                    </h1>
                </div>
            </div>
        </div>
    </div>
</header>
<div class="container mt-4">

    <div class="row">
        <div class="col-lg-4">
            <div class="card">
                <div class="card-header">Imagen de perfil</div>
                <div class="card-body text-center">
                    <img [src]="url | noimage" alt=".." class="image-profile rounded-circle mb-2">
                    <div class="small font-italic text-muted mb-4">JPG o PNG no mayor a 5 MB</div>
                    <button class="btn btn-primary" type="button" (click)="openModal(0)">
                        Actualizar foto de perfil
                    </button>
                </div>
            </div>
        </div>
        <div class="col-lg-8">
            <!-- Account details card-->
            <div class="card mb-4">
                <div class="card-header">Detalles de la cuenta</div>
                <div class="card-body">
                    <form [formGroup]="profileForm" (ngSubmit)="sendData(0)">

                        <div class="form-row">

                            <div class="col-md-6">
                                <app-input-text [label]="'Nombres'" [req]="'true'" [form]="profileForm"
                                    [model]="'name'"></app-input-text>
                            </div>
                            <div class="col-md-6">
                                <app-input-text [label]="'Apellidos'" [req]="'true'" [form]="profileForm"
                                    [model]="'lastname'"></app-input-text>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <app-input-number [label]="'DNi'" [req]="'true'" [form]="profileForm" [model]="'dni'"
                                        [type]="'dni'">
                                    </app-input-number>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <app-input-number [label]="'Número de celular'" [req]="false" [form]="profileForm"
                                    [model]="'phone'" [type]="'number'">
                                </app-input-number>

                            </div>
                        </div>
                        
                        <div class="form-group">
                            <app-input-text [label]="'Correo electrónico'" [req]="false" [form]="profileForm" [model]="'email'">
                            </app-input-text>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-6">
                                <button class="btn btn-primary" type="submit" id="btn-save">Guardar cambios</button>

                            </div>
                            <div class="col-md-6 ml-auto text-right">
                                <button class="btn btn-secondary " type="button" (click)="openModal(1)">
                                    Cambiar Contraseña
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal [name]="'update-profile'" [title]="'Actualizar Foto de perfil'" [size]="'md'">

    <div class="">
        <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'" (change)="onSelect($event)">
            <ngx-dropzone-label>
                <div>
                    <h2>Suba su foto aquí</h2>
                </div>
            </ngx-dropzone-label>
            <div class="text-center">
                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                    [removable]="true" (removed)="onRemove(f)">
                    <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                </ngx-dropzone-image-preview>
            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-center mt-4">
        <button type="button" class="btn btn-default" data-dismiss="modal">
            Cancelar
        </button>
        <button class="btn btn-primary" (click)="sendData(2)" id="btn-update" (disabled)="!isSaveFile">
            <i-feather name="save"></i-feather> Guardar
        </button>
    </div>
</app-modal>

<app-modal name="change-password" title="Cambiar Contraseña" [size]="'md'">
    <div class="">
        <form [formGroup]="userForm" (ngSubmit)="sendData(1)">
            <div class="">
                <div class="form-group">
                    <label class="small mb-1" for="inputPassword">Contraseña</label>
                    <input class="form-control" id="inputPassword" type="password" formControlName="password"
                        autocomplete="off" />
                </div>
                <div class="form-group">
                    <label class=" small mb-1" for="inputPasswordConfirm">Confirmar contraseña</label>
                    <input class="form-control" id="inputPasswordConfirm" type="password"
                        formControlName="passwordRepeat" autocomplete="off" />
                    <small class="invalid-feedback d-block">
                        {{getCheckPassword()}}
                    </small>
                </div>
            </div>
            <div class="row d-flex justify-content-end  mt-4">
                <button type="button" class="btn btn-default" data-dismiss="modal">
                    Cancelar
                </button>
                <button class="btn btn-primary" type="submit" id="btn-password" (click)="sendData(1)">
                    <i-feather name="save"></i-feather> Guardar cambios
                </button>

            </div>

        </form>
    </div>
</app-modal>