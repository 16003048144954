
import { Component, OnInit } from '@angular/core';

import { IreApiService } from '@ire/services/ire-api.service';

import { ModalService } from '@ire/services/modal.service';

import { RolInterface } from '@semantics/interfaces/Rol';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-rol',
  templateUrl: './rol.component.html',
  styleUrls: ['./rol.component.css']
})
export class RolComponent implements OnInit { 

	public loader : Boolean;

	public listRoles 	: RolInterface[];

	public rolSelected  : RolInterface; 

	public rolForm : FormGroup;

	public titleModal : String;

	public spinForm : Boolean = false;

	
	constructor(
		
		private sModal : ModalService,

		private sApi   : IreApiService,
		
		private formBuilder : FormBuilder

	) { }

	async ngOnInit() {

		this.loader = true; 

		await this.GetRoles();
	
		this.buildForm();

		this.loader = false;
	
	}

   	administrarMenu (item : RolInterface) {

		this.rolSelected = item;
		
		this.sModal.openFullView('menu');
	}

	private buildForm = () => {
		this.rolForm = this.formBuilder.group({
			nombre : ['',Validators.required],
			nivel : ['',Validators.required],
			alias : ['',Validators.required]
		});
	}

	openModalRol ( rol : RolInterface ) {
		
		console.log(rol);

		this.titleModal = 'Crear Rol';
		
		if(rol) {
			
			this.titleModal = 'Editar Rol';

			
		}
		
		this.sModal.openModal('rolModal');

	}

	async sendData () : Promise<any> {

		this.spinForm = true;

		let res = await this.sApi.post('rol/create',{});

		if (res.succes) {

			await this.GetRoles();

		}

	}

	async GetRoles () : Promise<any> {
		
		let res = await this.sApi.get('rol/all');
		
		this.listRoles = res.success ? res.info : [];
		
	}
}
