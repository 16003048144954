<div class="d-flex flex-column justify-content-center">
  <img class="img-fluid m-auto mb-4" [src]="image ? image : defaultImage" alt="image-empty" style="height: 10rem;" />
  <div class="text-center pt-2 px-0 px-lg-5">
    <h5>
      {{title}}
    </h5>
    <p class="mb-4">
      {{subTitle}}
    </p>
  </div>
</div>