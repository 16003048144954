import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class ModalService {

	properties: any = {
		type: undefined,
		message: undefined,
		methodAccept: undefined,
		methodClosed: undefined,
	}
	private setPropertiesSubject = new Subject<any>();
	setPropertiesObservable = this.setPropertiesSubject.asObservable();

	success(message: string, callbackAccept?: any, callbackClosed?: any) {
		this.setProperties('success', message, callbackAccept, callbackClosed);
	}

	info(message: string, callbackAccept?: any, callbackClosed?: any) {
		this.setProperties('info', message, callbackAccept, callbackClosed);
	}

	warning(message: string, callbackAccept?: any, callbackClosed?: any) {
		this.setProperties('warning', message, callbackAccept, callbackClosed);
	}

	error(message: string, callbackAccept?: any, callbackClosed?: any) {
		this.setProperties('error', message, callbackAccept, callbackClosed);
	}

	question(message: string, callbackAccept?: any, callbackClosed?: any) {
		this.setProperties('question', message, callbackAccept, callbackClosed);
	}

	openModal(name: string, callbackClosed?: any) {

		let modal = $(`#modal-${name}-ire-base`);

		modal.modal('show');

		modal.on('hide.bs.modal', () => {
			if (callbackClosed)
				callbackClosed();
		});
	}

	closeModal(name: string) {

		let modal = $(`#modal-${name}-ire-base`);

		modal.modal('hide');
	}

	openFullView(name: string,callbackClosed? : any) {

		let fulView = $(`#full-view-${name}`);

		fulView.modal('show');

		fulView.on('hide.bs.modal', () => {
			if (callbackClosed)
				callbackClosed();
		});
	}

	closeFullView(name: string) {

		let fulView = $(`#full-view-${name}`);

		fulView.modal('hide');
	}

	private setProperties = (type: string, message: string, callbackAccept?: any, callbackClosed?: any) => {

		this.properties.type = type

		this.properties.message = message;

		this.properties.methodAccept = callbackAccept ? callbackAccept : undefined;

		this.properties.methodClosed = callbackClosed ? callbackClosed : undefined;

		this.setPropertiesSubject.next(this.properties);

		this.openAlert();
	}
	
	private openAlert = () => {

		let alert = $('#ire-alert');

		alert.modal('show');

		alert.on('hide.bs.modal', () => {

			if (this.properties.methodClosed)

				this.properties.methodClosed();

			alert.off('hide.bs.modal');
		});
	}

	private closeAlert = () => {

		let alert = $('#ire-alert');

		alert.blur();

		alert.modal('hide');

	}

	resetProperties() {

		this.properties = {

			type: undefined,

			message: undefined,

			methodAccept: undefined,

			methodClosed: undefined,

		}

		this.setPropertiesSubject.next(this.properties);

		this.closeAlert();

	}
}
