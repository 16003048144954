
import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';

import { CheckLoginGuard } from '@auth/guards/check-login.guard';

import { CheckAuthGuard } from '@auth/guards/check-auth.guard';

import { LoginComponent } from '@auth/components/login/login.component';


import { SemanticsComponent } from '@semantics/components/semantics/semantics.component';







const routes: Routes = [

  {
    path: 'login', component: LoginComponent,
    canActivate: [CheckLoginGuard],
  },

  {
    path: 'app',

    component: SemanticsComponent,

    canActivate: [CheckAuthGuard],

    children: [

      {
        path: '', loadChildren: () =>
          import('./project/project.module')
            .then(m => m.ProjectModule)
      },

      { path: '**', pathMatch: 'full', redirectTo: '' },
    ]

  },

  { path: '**', pathMatch: 'full', redirectTo: 'app' },
];

@NgModule({

  imports: [RouterModule.forRoot(routes, { useHash: true })],

  exports: [RouterModule]
})

export class AppRoutingModule { }
