
import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { cApi } from '../constant/ire-constant';

import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class IreApiService {

	constructor(
		private http: HttpClient,
		public auth: AuthService,
	) { }

	async get(url: string): Promise<any> {

		try {

			const res = await this.http.get(cApi.SERVER + url).toPromise();

			return Promise.resolve(res);

		} catch (error) {
			
			if(error.status == 401)	return this.auth.logoutFor401();

			return Promise.reject(error);
		}

	}


	async post(url: string, data: any): Promise<any> {

		try {

			const res = await this.http.post(cApi.SERVER + url, data).toPromise();

			return Promise.resolve(res);

		} catch (error) {

			return error.error;
		}

	}
	async put(url: string, data: any): Promise<any> {

		try {

			const res = await this.http.put(cApi.SERVER + url, data).toPromise();

			return Promise.resolve(res);

		} catch (error) {

			return error.error;
		}

	}

	async delete(url: string): Promise<any> {

		try {

			const res = await this.http.delete(cApi.SERVER + url).toPromise();

			return Promise.resolve(res);

		} catch (error) {

			return error.error;
		}

	}

	download(route: string, fileName: string = null, data: any): Promise<any> {

		return new Promise((resolve, reject) => {

			this.http.post(cApi.SERVER + route, data,
				{
					responseType: 'blob',
					observe: 'response'
				}
			).subscribe((data: HttpResponse<any>) => {
				var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

				var blob = new Blob([data.body], { type: contentType });
				var url = window.URL.createObjectURL(blob);
				window.open(url);
			});
		});
	}

	downloadContact(route: string, fileName: string = null, data: any = {}): Promise<any> {
		return new Promise((resolve, reject) => {
			this.http.get(cApi.SERVER + route, {
				responseType: 'blob',
				observe: 'response'
			}).subscribe((response: HttpResponse<Blob>) => {
				var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
				var blob = new Blob([response.body], { type: contentType });
				var url = window.URL.createObjectURL(blob);
	
				var link = document.createElement('a');
				link.href = url;
				link.download = fileName || 'file.xlsx'; // Nombre por defecto si no se proporciona
				link.click();
				
				window.URL.revokeObjectURL(url);
	
				resolve({
					success: true,
					message: 'Archivo descargado correctamente.'
				});
			}, (error) => {
				reject(error);
			});
		});
	}
	

	downloadFile(route: string, filename: string = null, data: any, typeFile: string = 'application/pdf') : Promise<any> {


		return new Promise((resolve, reject) => {
			this.http.post(cApi.SERVER + route, data,
				{ 
					responseType: 'blob' as 'json' ,
					observe: 'response'
				}
			).subscribe((response: HttpResponse<any>) =>  {

				console.log(response);

				let blob = new Blob([response.body], { type: response.body.type });

				var downloadURL = window.URL.createObjectURL(blob);
				var link = document.createElement('a');
				link.href = downloadURL;
				link.download = filename;
				link.click();
 
				let res = {
					success: true,
					info: null,
					message: 'Generado correctamente.'
				}

				resolve(res);

			}, (err) => {
				reject(err);
			});
		});
	}
	
	BadRequest (res:any) {
		
		var stringErrors = ``;

		if(res.errors) {
			for (var key in res.errors) {
				if (res.errors.hasOwnProperty(key)) {
					stringErrors += `<small>${res.errors[key]}</small><br>`;
				}
			}
		} else {
			stringErrors += res.message;
		}


		return stringErrors;
	}

	
}
