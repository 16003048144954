<div id="layoutSidenav_nav">

    <nav class="sidenav shadow-right sidenav-light">

        <div class="sidenav-menu">

            <div class="nav accordion" id="accordionSidenav">

                <div *ngFor="let module of listModules">

                    <div class="sidenav-menu-heading">

                        {{module.nombre}}

                    </div>

                    <any *ngFor="let subOption of module.opciones">
                        
                        <ng-container *ngTemplateOutlet="option; context:{$implicit: subOption}"></ng-container>

                    </any>


                </div>

            </div>

        </div>

        <div class="sidenav-footer row">

            <div class="col-md-8 sidenav-footer-content">

                <div class="sidenav-footer-subtitle">Ha ingresado como:</div>

                <div class="sidenav-footer-title">{{user.persona?user.persona.nombres + ' ' + user.persona.apellidos:user.nombre }}</div>

            </div>

            <div class="col-md-4">

                <button class="btn btn-sm" *ngIf="!loading" (click)="update()">

                    <i class="fas fa-sync text-success"></i>

                </button>

                <div *ngIf="loading">

                    <i class="fas fa-spinner fa-spin"></i>

                </div>
            </div>

        </div>

    </nav>

</div>
<ng-template #option let-opcion>
    <a class="nav-link puntero" routerLinkActive="active" *ngIf="!opcion.opciones && opcion.visible_sidebar==1 "
        [routerLink]="['/app'+opcion.enlace]">

        <div class="nav-link-icon">
            <i class="{{opcion.icono}}"></i>
        </div>
        {{opcion.nombre}}
    </a>
    <a class="nav-link collapsed puntero" *ngIf="opcion.opciones" (click)="opcion.show=!opcion.show">

        <div class="nav-link-icon">

            <i class="{{opcion.icono}}"></i>

        </div>

        {{opcion.nombre}}

        <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
    </a>
    <div class="collapse " [ngClass]="{'show': opcion.show}" data-parent="#accordionSidenav">

        <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPages">

            <any *ngFor="let suboption of opcion.opciones">

                <ng-container *ngTemplateOutlet="option; context:{$implicit: suboption}"></ng-container>

            </any>

        </nav>

    </div>

</ng-template>