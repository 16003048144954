<div class="form-group" [formGroup]="form">
  <label for="id_{{label + Random }}" *ngIf="label">
    {{label}}:
    <span *ngIf="requerido" class="text-danger" style="font-size: 12px;">(*)</span>
  </label>
  <input type="{{type}}" class="form-control" name="{{modelo + Random}}" formControlName="{{modelo}}" 
    placeholder="{{placeholder}}" maxlength="{{maxLength}}"
    [ngClass]="{'is-invalid':form.get(modelo).invalid  && form.get(modelo).touched}" autocomplete="off">
  <app-input-validation [control]="form.get(modelo)" [modelo]="modelo" [label]="label"></app-input-validation>
</div>
