import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.css']
})
export class InputNumberComponent implements OnInit {

  @Input() label: string;
  @Input('req') requerido: boolean = true
  @Input('form') form: FormGroup;
  @Input('model') modelo: string;
  @Input() step: number = 1;
  @Input() type: string = "number";
  @Input('show') show: string = "";
  @Input('min') min: number;
  @Input('max') max: number;

  @Input('placeholder') placeholder: string = "";
  textSelect = "";
  constructor() { }

  ngOnInit() {

  }


  validar = (event) => {

    this.textSelect = document.getSelection().toString();
    let text = event.srcElement.value
    switch (this.type) {

      case "decimal":
        if (event.charCode != 0) {
          if (text.includes('-') && event.charCode == 45) {
            event.preventDefault();
          }
          if ((event.charCode < 48 || event.charCode > 57) && event.charCode != 46 && event.charCode != 45) {
            event.preventDefault();
          }
        }
        break;
      case "ruc":
        if (text.length >= 11) {
          if (text == this.textSelect) {
            text = '';
            this.textSelect = '';
          } else {

            event.preventDefault();
          }
        }
        if (event.charCode != 0) {
          if ((event.charCode < 48 || event.charCode > 57) || event.charCode == 46 || event.charCode == 45) {
            event.preventDefault();
          }
        }
        break;
      case "dni":
        if (text.length >= 8) {
          if (text == this.textSelect) {
            text = '';
            this.textSelect = '';
          } else {
            event.preventDefault();

          }
        }
        if (event.charCode != 0) {
          if ((event.charCode < 48 || event.charCode > 57) || event.charCode == 46 || event.charCode == 45) {
            event.preventDefault();
          }
        }
        break;
      case "number":
        if (event.charCode != 0) {
          if (text.includes('-') && event.charCode == 45) {
            event.preventDefault();
          }

          if ((event.charCode < 48 || event.charCode > 57) && event.charCode != 45) {
            event.preventDefault();
          }
        }
        break;
      default:
        break;
    }

  }


}
