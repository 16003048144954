
import { NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';

import { LoginComponent } from '@auth/components/login/login.component';

import { CheckLoginGuard }  from '@auth/guards/check-login.guard';

import { CheckAuthGuard }  from '@auth/guards/check-auth.guard';

import { AuthService } from './services/auth.service';


@NgModule({
    
    imports  : [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        HttpClientModule
    ],
    declarations: [
        LoginComponent
    ],

    exports : [],  

    providers : [
        CheckLoginGuard,
        CheckAuthGuard,
        AuthService
    ],

})
export class AuthModule { }
