
<div
    class="modal fade" id="modal-{{ name }}-ire-base"
    tabindex="-1" aria-hidden="true" role="dialog"
    attr.aria-labelledby="modal-{{ name }}-ire-base"
>
    <div class="modal-dialog modal-{{ size }}" [ngClass]="{'modal-dialog-scrollable': scroll}" role="document">

        <div class="modal-content">

            <div class="modal-header" *ngIf="!hideHeader">

                <h5 class="modal-title" id="modal-{{ name }}-ire-base">
                    {{ title }}
                </h5>

                <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>

                </button>

            </div>

            <div class="modal-body {{classbody}}">

                <ng-content></ng-content>

            </div>

        </div>

    </div>

</div>
