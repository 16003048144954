
import { Injectable } from '@angular/core';

import { NgxIzitoastService } from 'ngx-izitoast';

@Injectable({
  providedIn: 'root',
})

export class ToastService {

  constructor(
    public iziToast: NgxIzitoastService
  ) { }

  toastCarrito(title: string, message: string, icon: string, theme: string = 'light') {

    return this.iziToast.show({
      title: `${title}`,
      message: `${message}`,
      theme: `${theme}`,
      icon: `${icon}`,
      position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
      progressBarColor: 'rgb(0, 255, 184)',
      onClosed: () => {
        console.log("closed");
      }

    });

  }

  toastTopRight(title: string, message: string, icon: string, theme: string = 'light') {

    return this.iziToast.show({
      title: `${title}`,
      message: `${message}`,
      theme: `${theme}`,
      icon: `${icon}`,
      position: 'topRight', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
      progressBarColor: 'rgb(0, 255, 184)',
      onClosed: () => {
      }

    });

  }

  toastSuccess(title: string, message: string, icon: string = 'check-circle', theme: string = 'light') {

    return this.iziToast.success({
      title: `${title}`,
      message: `${message}`,
      theme: `${theme}`,
      icon: `${icon}`,
      position: 'bottomRight', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
      progressBarColor: 'rgb(0 85 255)',
      onClosed: () => {
        console.log("closed");
      }

    });

  }
  //rgb(0, 255, 184)

  toastError(title: string, message: string, icon: string) {

    return this.iziToast.error({
      title: `${title}`,
      message: `${message}`,
      icon: `${icon}`,
      position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
      progressBarColor: 'rgb(0, 255, 184)',
      onClosed: () => {
        console.log('closed');
      }
    });

  }


}
