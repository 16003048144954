import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { CommonModule } from '@angular/common';


import { IconsModule } from '@ire/icons/icons.module';

import { UserRoutingModule } from './user-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UserProfileComponent } from './components/user-profile/user-profile.component';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { NoimagePipe } from '@project/pipes/noimage.pipe';

import { IreModule } from '@ire/ire.module';

@NgModule({
  exports: [
    UserProfileComponent,
    NoimagePipe,

  ],
  declarations: [UserProfileComponent, NoimagePipe],

  imports: [
    CommonModule,
    IconsModule,
    UserRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    IreModule

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class UserModule { }
