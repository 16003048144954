
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {IreApiService} from '@ire/services/ire-api.service';
import { ModalService } from '@ire/services/modal.service';
import {ToastService} from '@ire/services/toast.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

	modal: any = {
		name: 'base'
	};
  pages :any[] =[];
  sections :any[] = [];
  loader:boolean = true;
  spin_loader:boolean = false;

	constructor(

		private _apiService: IreApiService,
		private router: Router,
		private _toastService: ToastService,
	) { }

	ngOnInit(): void {
    this.getData();
  }

  async getData(){
    this.loader = true;
    await this._apiService.get("element/get_dashboard")
    .then((ref)=>{
      if(ref.success){
        this.pages = ref.info.pages;
        this.sections = ref.info.sections;
      }
    })
    .finally(()=> this.loader = false)
  }

  async generateJSON(){
    this.spin_loader = true;
    await this._apiService.get("element/generate_all_json")
    .then((ref)=>{
      if(ref.success){
        this._toastService.toastSuccess("Correcto", ref.message);
      }
      else{
        this._toastService.toastError("Error", ref.message,"");
      }
    })
    .finally(()=> this.spin_loader = false)
  }


	goTo(item, section) {
    this.router.navigateByUrl(`/app/administrator/section/${section}/${item.id}`);
	}
  goToGeneral(){
    this.router.navigateByUrl(`/app/administrator/general`);
  }


}
