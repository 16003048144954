import { environment } from "src/environments/environment"


export const cSession = {
    MENU    : `${window.location.hostname}@ire_basic@menu`,
    USER    : `${window.location.hostname}@ire_basic@user`,
    ICON    : `${window.location.hostname}@ire_basic@icon`,
    TOKEN   : `${window.location.hostname}@ire_basic@token`,
    MODULES : `${window.location.hostname}@ire_basic@modules`,
    ACCESS  : `${window.location.hostname}@ire_basic@access`,
    RUTAS   : `${window.location.hostname}@ire_basic@rutas`,
    MAX_TIME: `${window.location.hostname}@ire_basic@max_time`,
    DATAFORMAT: `${window.location.hostname}@ire_basic@dataformat`,
    BMPFORMAT: `${window.location.hostname}@ire_basic@bmpformat`,
    ROLES   : `${window.location.hostname}@ire_basic@roles`
}


export const cLogin = {

    /** dev */

    AUTH: environment.baseApiUrl + "auth/login",
    LOGOUT: environment.baseApiUrl + "auth/logout"
    /** production */

    //AUTH : 'dominio.com/admin/api/public/api/' 

}