
<div class="container">

    <div class="row">

        <ng-container *ngIf="items">

            <div 
                class="mb-4" 
                [ngClass]="{'col-xl-4 col-md-6': !vertical, 'col-md-12' : vertical }"
                *ngFor="let key of items  | loopNumber">
                
                <div class="card lift h-100 card-header-actions border-top-0 border-left-0 border-right-0  h-100">
                        
                    <div class="card-header">
        
                        <h4></h4>
        
                        <div class="dropdown no-caret">
                
                            <button class="btn btn-icon">
                                <i></i>    
                                
                            </button>
                
                        </div>
        
                    </div>
        
                    <div class="card-body text-center">
                        
                        <button class="btn btn-sm py-2 px-3">
                            
                            <h3></h3>
        
                        </button>
        
                    </div>
                    
                </div>   
        
            </div>
        
        </ng-container>

    </div>

</div>

