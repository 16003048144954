
<div class="modal fade" id="ire-alert" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    
    <div class="modal-dialog modal-notify" 
        role="document">
        <div class="modal-content">
            <div class="modal-header">

                <p class="heading lead"></p>

                <button 
                    type="button" 
                    class="close"  
                    data-dismiss="modal" 
                    aria-label="Close"
                    (click)="cancelAlert();"
                >
                <span aria-hidden="true" class="white-text">&times;</span>
                </button>
            </div>

            <div class="modal-body pt-0">

                <div class="text-center">

                    <div *ngIf="(modal.properties.type == 'success')">

                        <i class="far fa-smile fa-3x text-success"  ></i>
                    
                    </div>
                    
                    <div *ngIf="(modal.properties.type == 'info')">

                        <i class="fas fa-info fa-3x text-info"></i>

                    </div>

                    <div *ngIf="(modal.properties.type == 'warning')">

                        <i class="far fa-bell fa-3x text-warning"></i>

                    </div>

                    <div *ngIf="(modal.properties.type == 'error')">

                        <i class="far fa-frown fa-3x text-error"></i>

                    </div>
                    
                    <div *ngIf="(modal.properties.type == 'question')">

                        <i class="fas fa-question fa-3x text-info"></i>
                    
                    </div>

                    <br>
                    
                    <p [innerHTML]="modal.properties.message ">
                    
                    </p>


                </div>

            </div>

            <!--Footer-->
            <div class="modal-footer justify-content-center">
                
                <button 
                    *ngIf="(modal.properties.type == 'question')"
                    type="button" 
                    class="btn waves-effect btn-outline-question btn-sm" 
                    data-dismiss="modal"
                    (click)="cancelAlert();">
                    Cancelar
                </button>
                
                <button 
                    type="button" 
                    class="btn btn-sm"
                    [ngClass] = " 
                        {
                            'btn-success' : (modal.properties.type == 'success'),
                            'btn-info'    : (modal.properties.type == 'info' || modal.properties.type == 'question'),
                            'btn-warning' : (modal.properties.type == 'warning'),
                            'btn-danger'  : (modal.properties.type == 'error')
                        }" 
                    (click)="acceptAlert();"> 
                    Aceptar
                </button>
                
            </div>
        </div>
   </div>
</div>